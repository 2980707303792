<template>
        <defaultSec :title="'优惠券查询'">
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="批次ID">
                    <el-input v-model="formParam.ticketBatchId" placeholder="请输入批次ID" clearable></el-input>
                </el-form-item>
                <el-form-item label="批次名称">
                    <el-input v-model="formParam.ticketBatchName" placeholder="请输入批次名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="生成类型">
                    <el-select v-model="formParam.createType" placeholder="请选择生成类型" clearable >
                        <el-option
                                v-for="item in createTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="优惠方式">
                    <el-select v-model="formParam.discountMode" placeholder="请选择优惠方式" clearable>
                        <el-option
                                v-for="item in discountModeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                </el-form-item>
            </el-form>
            <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column prop="ticketBatchId" label="批次ID"></el-table-column>
                    <el-table-column prop="ticketBatchName" label="批次名称"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                    <el-table-column prop="createTypeName" label="类型"></el-table-column>
                    <el-table-column prop="ticetClassName" label="分类"></el-table-column>
                    <el-table-column prop="discountModeName" label="折扣"></el-table-column>
                    <el-table-column prop="discountOrAmount" label="金额/比例" sortable></el-table-column>
                    <el-table-column prop="minUseamount" label="最低使用金额" sortable></el-table-column>
                    <el-table-column prop="quantity" label="兑换/发送次数" sortable></el-table-column>
                    <el-table-column prop="alreadyQuantity" label="已领取" sortable></el-table-column>
                    <el-table-column prop="userTackCount" label="每人最多领取" sortable></el-table-column>
                    <el-table-column :formatter="convertStatus" label="状态"></el-table-column>
                    <!--  <el-table-column :formatter="convertVisible" label="可见"></el-table-column>-->
                    <!--   <el-table-column :formatter="convertCashType" label="券号类型"></el-table-column>-->
                    <el-table-column prop="userName" label="创建人"></el-table-column>
                    <!-- <el-table-column prop="remark" label="备注"></el-table-column>-->
                    <el-table-column label="操作" fixed="right" width="290">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" @click="handleCashBatchPage(scope.row)">查看</el-button>
                            <el-button type="warning" size="mini" @click="handleUpdateRule(scope.row)">规则</el-button>
                            <el-button type="success" size="mini" @click="handleCashGetListPage(scope.row)">券明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </tablePagination>
          <el-dialog title="优惠券明细" :visible.sync="dialogDetail" fullscreen>

            <couponDetail :ticketBatchId="ticketBatchId"></couponDetail>


          </el-dialog>
          <el-dialog title="优惠券规则" :visible.sync="dialogRule" fullscreen>

            <couponRule :ticketBatchId="ticketBatchId"></couponRule>

          </el-dialog>
          <el-dialog title="批次查看" :visible.sync="dialogBatch" fullscreen>

            <couponBatch :ticketBatch="ticketBatch" :queryFilter="queryFilter"></couponBatch>

          </el-dialog>
        </defaultSec>


</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import couponDetail from '@c/coupon/couponDetail'
    import couponRule from "@c/coupon/couponRule"
    import couponBatch from "@c/coupon/couponBatch"
    export default {
        data() {
            return {
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    ticketBatchId:'',
                    ticketBatchName:'',
                    createType:null,
                    discountMode:null,
                },
                createTypeArr:[{value:3,name:"领取时生成劵码"},{value:2,name:"创建时生成券码"}],
                discountModeArr:[{value:0,name:"满减券"},{value:1,name:"折扣券"}],
                tableList:[],
                total:0,
                ticketBatchId:'',
                ticketBatch:{},
                dialogDetail:false,
                dialogRule:false,
                dialogBatch:false,
                queryFilter:{}
            }
        },
        components:{
            defaultSec,tablePagination,couponDetail,couponRule,couponBatch
        },
        mounted(){
            this.initList();
            this.getQueryList();
        },
        methods: {
            initList() {
                this.$apiGet('lechun-cms/cash/getCashticketBatchList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list

                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            handleCashGetListPage(row) {


                this.ticketBatchId = row.ticketBatchId;
                console.log(this.ticketBatchId);
                this.dialogDetail = true;

            },
            handleUpdateRule(row){
                this.ticketBatchId = row.ticketBatchId;
                console.log(this.ticketBatchId);
                this.dialogRule = true;
            },
            handleCashBatchPage(row){
                this.ticketBatch = row;

                console.log(this.ticketBatch);
                this.dialogBatch = true;
            },
            convertStatus(row, column, cellValue) {
                if (row.status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            },
            getQueryList(){
                this.$apiGet('lechun-cms/cash/getQueryList',{}).then(res => {
                    this.queryFilter=res;
                })
            },

        },

    }
</script>


<style scoped>

</style>