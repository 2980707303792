<template>
    <div>

        <el-form :model="curr_ticketBatch" label-width="120px" size="small ">
            <el-form-item label="举例注意">
                <el-alert title="如果生成类型选择优惠码，生成优惠码总数为100, 兑换/发送次数为20，最多领取为30 表示：总共生成100个兑换码，每个兑换码可以兑换20次，本批次共可以兑换2000次，但是每个用户只可以领取30次" type="success" :closable="false">
                </el-alert>
            </el-form-item>
            <el-form-item label="批次标识">
                <el-input v-model="curr_ticketBatch.ticketBatchId" placeholder="无需输入" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="批次名称" :required="true">
                <el-input v-model="curr_ticketBatch.ticketBatchName" placeholder="批次名称" :disabled="allowEdit" clearable ></el-input>
            </el-form-item>
            <el-form-item label="金额类型">
                <el-select :popper-append-to-body="true" v-model="curr_ticketBatch.ticketType" :disabled="allowEdit">
                    <el-option
                            v-for="item in ticketTypeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="批次状态">
                <el-select :popper-append-to-body="true" v-model="curr_ticketBatch.status" :disabled="allowEdit">
                    <el-option
                            v-for="item in statuseList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="批次分类">
                <el-select :popper-append-to-body="true" v-model="curr_ticketBatch.ticetClass" :disabled="allowEdit" filterable>
                    <el-option
                            v-for="item in getTicetClassList"
                            :key="item.dictionaryKey"
                            :label="item.dictionaryName"
                            :value="item.dictionaryKey">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否可见">
                <el-select :popper-append-to-body="true" v-model="curr_ticketBatch.userVisible" :disabled="allowEdit">
                    <el-option
                            v-for="item in userVisibleList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!--<el-form-item label="是否数字">
                <el-select :popper-append-to-body="false" v-model="curr_ticketBatch.digital">
                    <el-option
                            v-for="item in digitalList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>-->
            <el-form-item label="生成类型"  >
                <el-select :popper-append-to-body="true" :disabled="allowEdit" v-model="curr_ticketBatch.createType"  filterable @change="changeCreateType">
                    <el-option
                            v-for="item in createTypeArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
                <span style="color: red">由用户手工兑换的优惠券请选择创建时生成券码(如员工券、客服补发券),除此以外的选领取时生成券码</span>
            </el-form-item>

            <el-form-item label="优惠码" v-show="ticketNo_show">
                <el-input v-model="curr_ticketBatch.ticketNo" placeholder="优惠码" maxlength="10" :disabled="allowEdit"></el-input>
            </el-form-item>
            <el-form-item label="优惠券总数" v-show="false">
                <el-input-number v-model="curr_ticketBatch.ticketNoNum" :min="0" :max="1000000"  label="优惠券总数" :disabled="allowEdit"></el-input-number>
                <span style="color: red">*优惠券总数量</span>
            </el-form-item>
            <el-form-item label="位数" v-show="!ticketNo_show">
                <el-input-number v-model="curr_ticketBatch.ticketPosition" :min="1" :max="12" label="位数" :disabled="allowEdit"></el-input-number>
            </el-form-item>
            <el-form-item label="优惠方式">
                <el-select :popper-append-to-body="true" :disabled="allowEdit" v-model="curr_ticketBatch.discountMode" filterable @change="changeDiscountMode">
                    <el-option
                            v-for="item in discountModeArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="优惠券金额" v-show="amount_show">
                <el-input-number v-model="curr_ticketBatch.amount"  :disabled="allowEdit" :min="0" :max="10000" label="优惠券金额"></el-input-number>
                <span style="color: red">*优惠券金额</span>
            </el-form-item>
            <el-form-item label="折扣" v-show="discountAmount_show">
                <el-input-number v-model="curr_ticketBatch.discountAmount" :min="0" :step="1" :max="100" label="折扣" :disabled="allowEdit"></el-input-number>
            </el-form-item>
            <el-form-item label="满多少可用">
                <el-input-number v-model="curr_ticketBatch.minUseamount" :min="0" :max="1000" label="满多少可用" :disabled="allowEdit"></el-input-number>
            </el-form-item>
            <el-form-item label="优惠券数量" >
                <el-input-number v-model="curr_ticketBatch.quantity" :min="0" :max="1000000" :step="1" label="优惠券数量" :disabled="allowEdit"></el-input-number>
            </el-form-item>
            <el-form-item label="跳转url" >
                <el-input v-model="curr_ticketBatch.cashUseUrl" placeholder="e.g https://wx.lechun.cc" :disabled="allowEdit"></el-input>
                <span style="color: red">专用连接券填写</span>
            </el-form-item>
            <el-form-item label="期限">
                <el-select :popper-append-to-body="false" v-model="curr_ticketBatch.periodType" @change="changePeriodType" :disabled="allowEdit">
                    <el-option
                            v-for="item in periodTypeList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="天数" v-show="days_show">
                <el-input-number v-model="curr_ticketBatch.days" :min="1" :step="1" :max="1000" label="天数" :disabled="allowEdit"></el-input-number>
                <span style="color: red">不算兑换当天噢~~</span>
            </el-form-item>
            <el-form-item label="开始日期" v-show="beginTime_show">
                <el-date-picker
                        v-model="curr_ticketBatch.beginTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="allowEdit"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期" v-show="endTime_show">
                <el-date-picker
                        v-model="curr_ticketBatch.endTime"
                        type="date"
                        :disabled="allowEdit"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="最多领取">
                <el-input-number v-model="curr_ticketBatch.userTackCount" :min="0" :step="1" :max="10000"  label="最多领取 0无限制" :disabled="allowEdit"></el-input-number>
                <span style="color: red">*每个用户最多可以领取数量~~</span>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" :rows="2" placeholder="会显示在优惠劵列表" v-model="curr_ticketBatch.remark" :disabled="allowEdit"></el-input>
                <span style="color: red">*会显示在优惠劵列表 标注使用规则</span>
            </el-form-item>
        </el-form>


    </div>

</template>

<script>
    export default {
        name: "couponBatch",
        data(){
            return {
                curr_ticketBatch:{},
                ticketNoNum_show:false,
                ticketNo_show:false,
                beginTime_show:false,
                endTime_show:false,
                amount_show:false,
                days_show:true,
                discountAmount_show:false,
                createType_show:false,
                allowEdit:true,
                getTicetClassList:this.queryFilter.ticetClassList,
                ticketTypeList: [{value: 0, name: '固定金额'}, {value: 1, name: '可变金额'}],
                periodTypeList: [{value: 1, name: '日期'}, {value: 2, name: '天数'}],
                statuseList: [{value: 1, name: '启用'}, {value: 0, name: '禁用'}],
                statusRuleList: [{value: 1, name: '全部可用'}, {value: 3, name: '部分可用'}],
                userVisibleList: [{value: 1, name: '可见'}, {value: 0, name: '不可见'}],
                digitalList: [{value: 1, name: '数字'}, {value: 0, name: '混合'}],
                createTypeArr:[{value:1,name:"优惠码"},{value:2,name:"优惠券"}],
                discountModeArr:[{value:0,name:"满减券"},{value:1,name:"折扣券"}],
            }
        },
        components:{

        },
        mounted(){
            //this.getQueryList();
            console.log(this.queryFilter);
            this.getTicetClassList=this.queryFilter.ticetClassList;
            this.changeCreateType(this.curr_ticketBatch.createType);
            this.changeDiscountMode(this.curr_ticketBatch.discountMode);
            this.changePeriodType(this.curr_ticketBatch.periodType);
            this.curr_ticketBatch=this.ticketBatch;
            this.curr_ticketBatch.ticetClass=this.curr_ticketBatch.ticetClass+'';
        },
        watch: {
            ticketBatch(val){
                //this.getQueryList();
                this.getTicetClassList=this.queryFilter.ticetClassList;
                this.curr_ticketBatch=this.ticketBatch;
                this.curr_ticketBatch.ticetClass=this.curr_ticketBatch.ticetClass+'';
                this.changeCreateType(this.curr_ticketBatch.createType);
                this.changeDiscountMode(this.curr_ticketBatch.discountMode);
                this.changePeriodType(this.curr_ticketBatch.periodType);

            }
        },
        methods:{
            // getQueryList(){
            //     this.$apiGet('lechun-cms/cash/getQueryList',{}).then(res => {
            //         console.log(res)
            //         this.getTicetClassList = res.ticetClassList;
            //         console.log(this.getTicetClassList);
            //         this.createTypeArr=res.cashCreateTypeEnum;
            //         this.discountModeArr= res.cashDiscountModeEnum;
            //         //this.curr_ticketBatch=this.ticketBatch;
            //     })
            // },
            changeCreateType(newValue) {
                if (newValue == '1') {
                    //兑换码
                    this.ticketNoNum_show = true;
                    this.ticketNo_show=true;

                } else {
                    //优惠劵
                    this.ticketNoNum_show = false;
                    this.ticketNo_show=false;
                }
            },
            changeDiscountMode(newValue) {
                if (newValue == '0') {
                    //满减
                    this.discountAmount_show = false;
                    this.amount_show = true;
                } else if (newValue == '1') {
                    //折扣
                    this.discountAmount_show = true;
                    this.amount_show = false;
                }
            },
            changePeriodType(newValue) {
                if (newValue == '1') {
                    //日期
                    this.beginTime_show = true;
                    this.endTime_show = true;
                    this.days_show = false;

                } else if (newValue == '2') {
                    //天数
                    this.beginTime_show = false;
                    this.endTime_show = false;
                    this.days_show = true;
                }
            },
        },

        props:['ticketBatch','queryFilter'],
    }
</script>

<style scoped>

</style>