<template>
    <div>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
<!--                <el-form-item label="批次ID">-->
<!--                    <el-input v-model="formParam.ticketBatchId" placeholder="请输入批次ID" readOnly="true"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="券号">
                    <el-input v-model="formParam.ticketNo" placeholder="请输券号" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="formParam.status" placeholder="请选择状态" clearable >
                        <el-option
                                v-for="item in statusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                    <el-button type="success" @click="downloadCashticketList">导出</el-button>
                </el-form-item>
            </el-form>
            <tablePaginationDetail :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column prop="ticketId" label="标示"></el-table-column>
                    <el-table-column prop="ticketName" label="名称"></el-table-column>
                    <el-table-column prop="ticketNo" label="优惠券(码)号"></el-table-column>
                    <el-table-column prop="ticketAmount" label="金额"></el-table-column>
                    <el-table-column prop="discountAmount" label="折扣"></el-table-column>
                    <el-table-column prop="codeStatusName" label="优惠券状态"></el-table-column>
                    <el-table-column prop="activtionCount" label="已激活数量"></el-table-column>
                    <el-table-column prop="cashStatusName" label="使用状态"></el-table-column>
                    <el-table-column prop="orderMainNo" label="母订单号"></el-table-column>
                    <el-table-column prop="createTime" label="创建日期	"></el-table-column>
                    <el-table-column prop="usedTime" label="使用时间"></el-table-column>
                    <el-table-column prop="beginTime" label="有效期(开始)"></el-table-column>
                    <el-table-column prop="endTime" label="有效期(结束)"></el-table-column>
                    <el-table-column prop="realyDeduction" label="使用金额"></el-table-column>
                    <el-table-column prop="nickName" label="领取人"></el-table-column>
                    <el-table-column prop="totalAmount" label="订单总金额"></el-table-column>

                </el-table>
            </tablePaginationDetail>


    </div>

</template>

<script>
    import tablePaginationDetail from '@c/tablePagination'
    export default {
        name: "couponDetail",
        data(){
            return {
                curr_ticketBatchId:this.ticketBatchId,
                formParam:{
                    pageSize:50,
                    currentPage:1,
                    ticketBatchId:'',
                    ticketNo:'',
                    status:'',
                    discountMode:null,
                },
                statusArr:[{value:0,name:"待激活"},{value:1,name:"未使用"},{value:2,name:"已使用"},{value:3,name:"已过期"},{value:4,name:"已部分使用"},{value:5,name:"已禁用"}],
                tableList:[],
                total:0,

            }
        },
        components:{
            tablePaginationDetail
        },
        mounted(){
            this.initList();
        },
        watch: {
            ticketBatchId(val){
                this.curr_ticketBatchId=val;
                this.formParam.ticketNo='';
                this.formParam.status='';
                this.initList();
            },
            deep: true
        },
        methods: {
            initList() {
                this.formParam['ticketBatchId']=this.curr_ticketBatchId;
                this.$apiGet('lechun-cms/cash/getCashticketGetList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list

                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            downloadCashticketList(){
                window.open(this.$baseUrl+`/lechun-cms/cash/downloadCashticketList?ticketBatchId=${this.formParam.ticketBatchId}&status=${this.formParam.status}&pageSize=99999&currentPage=${this.formParam.currentPage}&pageRouterUrl=prizelist`)
            },
        },
        props:['ticketBatchId'],
    }
</script>

<style scoped>

</style>