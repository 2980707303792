<template>
    <div>

            <tablePaginationDetail :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column prop="ruleId" label="标示"></el-table-column>
                    <el-table-column prop="ruleTypeName" label="规则类型"></el-table-column>
                    <el-table-column prop="stats" :formatter="convertStatusRule" label="状态"></el-table-column>
                    <el-table-column prop="detailIdNames" label="详情"></el-table-column>

                </el-table>
            </tablePaginationDetail>


    </div>

</template>

<script>
    import tablePaginationDetail from '@c/tablePagination'
    export default {
        name: "couponRule",
        data(){
            return {
                curr_ticketBatchId:this.ticketBatchId,
                formParam:{
                    pageSize:50,
                    currentPage:1,
                    ticketBatchId:''

                },
                tableList:[],
                total:0,

            }
        },
        components:{
            tablePaginationDetail
        },
        mounted(){
            this.initList();
        },
        watch: {
            ticketBatchId(val){
                this.curr_ticketBatchId=val;
                this.initList();
            }
        },
        methods: {
            initList() {
                this.formParam['ticketBatchId']=this.curr_ticketBatchId;
                this.$apiGet('lechun-cms/cash/getCashticketBatchUseRuleList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list

                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            convertStatusRule(row, column, cellValue){
                if (cellValue == 1) {
                    return "全部可用";
                } else {
                    return "部分可用";
                }
            },
        },
        props:['ticketBatchId'],
    }
</script>

<style scoped>

</style>